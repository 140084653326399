.App {
  text-align: center;
  position: absolute;
  width: 100%;
  text-align: left;
  font-weight: bold;
  padding-left: 20%;
  padding-right: 20%;
}


