body {
  margin: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("./images/D_Desktop_Background.png");
  font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 14px;
  color: #F6F5BF;
}

code {
  font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #F6F5BF;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #F6F5BF;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #F6F5BF;
}

input[type=text] {
  width: 100%;
  padding: 5px 5px;
  margin: 8px 0;
  border: 2px solid #F6F5BF;
  color: #F6F5BF;
  background: transparent;
}

input[type=text]:focus {
  width: 100%;
  border: 2px solid #F6F5BF;
  outline: none;
}

div.row {
  display: table;
  width: 100%;
  table-layout: fixed;    /* For cells of equal size */
  font-size: 0.75em;
  padding-top: 1em;
}
div.row span {
  display: table-cell;
  text-align: center;
}