
.body {
  width: 100%;
  padding: 1em;
  margin: 1em 0;
  border: 2px solid #F6F5BF;
  border-radius: 0.5em;
  color: #F6F5BF;
  background: transparent;
  font-size: 1.5em;
  font-weight: 100;
}

.icon {
  cursor: pointer;
}

div.slider {
  padding-top: 1em;
  padding-left: 25%;
  padding-right: 25%;
}

p.truth {
  padding: 0.5em;
  text-align: center;
  font-size: 2em;
}