body {
  margin: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url(/static/media/D_Desktop_Background.d5f362d4.png);
  font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 14px;
  color: #F6F5BF;
}

code {
  font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
}

::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #F6F5BF;
  opacity: 1; /* Firefox */
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #F6F5BF;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #F6F5BF;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #F6F5BF;
}

input[type=text] {
  width: 100%;
  padding: 5px 5px;
  margin: 8px 0;
  border: 2px solid #F6F5BF;
  color: #F6F5BF;
  background: transparent;
}

input[type=text]:focus {
  width: 100%;
  border: 2px solid #F6F5BF;
  outline: none;
}

div.row {
  display: table;
  width: 100%;
  table-layout: fixed;    /* For cells of equal size */
  font-size: 0.75em;
  padding-top: 1em;
}
div.row span {
  display: table-cell;
  text-align: center;
}
.App {
  text-align: center;
  position: absolute;
  width: 100%;
  text-align: left;
  font-weight: bold;
  padding-left: 20%;
  padding-right: 20%;
}




.body {
  width: 100%;
  padding: 1em;
  margin: 1em 0;
  border: 2px solid #F6F5BF;
  border-radius: 0.5em;
  color: #F6F5BF;
  background: transparent;
  font-size: 1.5em;
  font-weight: 100;
}

.icon {
  cursor: pointer;
}

div.slider {
  padding-top: 1em;
  padding-left: 25%;
  padding-right: 25%;
}

p.truth {
  padding: 0.5em;
  text-align: center;
  font-size: 2em;
}
.main { 
  font-family: 'Inconsolata';
  padding: 5em;
  color: #ffffff;
  background: linear-gradient(0deg, #C6ACDF 0%, #E19020 33%, #E19020 71%,#C6ACDF 100%);
}
.main { 
  font-family: 'Inconsolata';
  padding: 5em;
  color: #ffffff;
  background: linear-gradient(0deg, #C6ACDF 0%, #E19020 33%, #E19020 71%,#C6ACDF 100%);
}
.main { 
  font-family: 'Inconsolata';
  padding: 5em;
  color: #ffffff;
  background: linear-gradient(0deg, #C6ACDF 0%, #E19020 33%, #E19020 71%,#C6ACDF 100%);
}
